import {
  GET_PACKAGE_DETAILS,
  GET_PACKAGE_DETAILS_SUCCESS,
  GET_PACKAGE_DETAILS_FAIL,
  CLEAR_PACKAGE_DETAILS,
  GET_PACKAGE_PRICING,
  GET_PACKAGE_PRICING_SUCCESS,
  GET_PACKAGE_PRICING_FAIL,
  CLEAR_PACKAGE_PRICING,
} from "./actionTypes";

const initialState = {
  packages: {},
  pricing: {},
  loader: false,
  error: {
    message: "",
  },
  detailsError: {
    message: "",
  },
};

const PackageDetailsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PACKAGE_DETAILS:
      state = { ...state, detailsError: { message: "" }, loader: true };
      break;
    case GET_PACKAGE_DETAILS_SUCCESS:
      state = { ...state, packages: action.payload, loader: false };
      break;
    case GET_PACKAGE_DETAILS_FAIL:
      state = {
        ...state,
        detailsError: {
          message: "Error",
        },
        loader: false,
      };
      break;
    case CLEAR_PACKAGE_DETAILS:
      state = { ...state, packages: {}, loader: false };
      break;
    case GET_PACKAGE_PRICING:
      state = { ...state, loader: true };
      break;
    case GET_PACKAGE_PRICING_SUCCESS:
      state = { ...state, pricing: action.payload, loader: false };
      break;
    case GET_PACKAGE_PRICING_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loader: false,
      };
      break;
    case CLEAR_PACKAGE_PRICING:
      state = { ...state, pricing: {}, loader: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PackageDetailsReducer;