import { useEffect, useState } from "react";
import { amenitiesIconMapping } from "src/constants/iconMapping";
import { experienceApiAxios } from "src/helpers/api_helper";
import { GET_PROPERTY_LISTING } from "src/helpers/url_helper";
import _ from "underscore";
import "./ItineraryStays.scss";
import RoomTypesContainer from "../details/RoomTypesContainer";
import "../details/Details.scss";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import ImageGalleryPopup from "../details/ImageGalleryPopup";
import rightArrowImg from "../../assets/images/enable-right.png";
import leftArrowImg from "../../assets/images/enable-left.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CommonTooltip from "../common/CommonTooltip";
import {
  formatPropertyDescription,
  getValueAccomodationType,
} from "src/helpers/utils";
import { Rating, Tooltip } from "@mui/material";
import { Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
});
const ItineraryStaysView = ({
  cityName,
  roomTypes,
  propertyId,
  roomTypeId,
  roomCount,
  mealPlan,
  screenWidth,
}: {
  cityName: string;
  roomTypes: any[];
  propertyId: string;
  roomTypeId: string;
  roomCount: number;
  mealPlan: string;
  screenWidth: number;
}) => {
  const [propertyDetails, setPropertyDetails] = useState<any>({});
  const [roomDetails, setRoomDetails] = useState<any>({});
  const [selectedviewmoreid, setselectedviewmoreid] = useState("");
  const [facilityLength, setfacilityLength] = useState(4);
  const [imageOverLayArray, setImageOverLayArray] = useState<any[]>([]);
  const [singleItenaryDescription, setSingleItenaryDescription] = useState(350);
  const [singleRoomAmentities, setSingleRoomAmentities] = useState(4);
  const [imageIndex, setImageIndex] = useState<any>(null);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      const response = await experienceApiAxios.get(
        `${GET_PROPERTY_LISTING}/${propertyId}`
      );

      if (response.status === 200) {
        const propertyResponse = response.data;
        let roomResponse: any = null;
        propertyResponse.roomTypes.forEach((room: any) => {
          if (room.id === roomTypeId) {
            roomResponse = room;
          }
        });

        setPropertyDetails(propertyResponse);
        setRoomDetails(roomResponse);
      }
    };

    fetchPropertyDetails();
  }, []);

  const getIcon = (text: string) => {
    const textVar = text?.toLowerCase() ?? "";
    let bestMatch = "";
    let bestMatchIndex = 0;
    let matchingIcons = amenitiesIconMapping.filter((icon: any) =>
      textVar?.includes(icon.keyword)
    );
    for (let i = 0; i < matchingIcons.length; i++) {
      if (matchingIcons[i].keyword !== undefined) {
        if (matchingIcons[i].keyword.length > bestMatch.length) {
          bestMatch = matchingIcons[i].keyword;
          bestMatchIndex = i;
        }
      }
    }
    if (matchingIcons.length === 0)
      return require(`../../assets/images/icons/highlights/default.png`);
    return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
  };

  const [showCarousel, setShowCarousel] = useState(false);

  const updateShowImageGalleryPopup = (data: any) => {
    setShowCarousel(data);
  };

  const toggleCarousel = () => {
    setShowCarousel(!showCarousel);
  };

  const [startIndex, setStartIndex] = useState(0);

  const prevImages = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 3);
    }
  };

  const nextImages = (carouselImagesLength: any) => {
    if (startIndex + 3 < carouselImagesLength) {
      setStartIndex(startIndex + 3);
    }
  };

  useEffect(() => {}, [itineraryPlanStore.propertyList]);

  useEffect(() => {
    if (propertyDetails) {
      const existingProperty = itineraryPlanStore.selectedProperties.find(
        (property: any) => property.cityId == propertyDetails.cityId
      );
      if (existingProperty) {
        const tempProperty = itineraryPlanStore.selectedProperties.filter(
          (property: any) => property.cityId != propertyDetails.cityId
        );
        tempProperty.push(propertyDetails);
        itineraryPlanStore.setSelectedProperties(tempProperty);
      } else {
        let tempProperty = itineraryPlanStore.selectedProperties;
        tempProperty = tempProperty.concat(propertyDetails);
        itineraryPlanStore.setSelectedProperties(tempProperty);
      }

      if (propertyDetails) {
        const imageArray: any[] = [];
        propertyDetails?.mediaFolder?.files?.map((file: any) => {
          const tempImg = {
            imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`,
            imageCaption: file.fileName,
          };
          imageArray.push(tempImg);
        });
        const mediaOverLayArray = [
          {
            collectionType: "Additional",
            propertyId: null,
            roomTypeId: null,
            images: imageArray,
          },
        ];
        setImageOverLayArray(mediaOverLayArray);
      }
    }
  }, [propertyDetails]);

  const getImageArrForRoom = (room: any) => {
    const imgArr: any[] = [];
    room?.mediaFolder?.files?.map((file: any) => {
      const tempImg = {
        imageCaption: file.fileName,
        imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/room/${room.mediaFolder.url}/${file.fileName}`,
      };
      imgArr.push(tempImg);
    });

    return imgArr;
  };

  const moreFacilitylist = (total: number, propertyid: any) => {
    setfacilityLength(total);
    setselectedviewmoreid(propertyid);
  };

  if (screenWidth < 700) {
    return (
      <>
        {propertyDetails && (
          <div className="stays-section-resp">
            <ThemeProvider theme={theme}>
              <Grid container spacing={5}>
                {/* First Child */}
                <Grid item xs={12} md={5} xl={4}>
                  {roomTypes.length == 0
                    ? propertyDetails?.mediaFolder?.files.length > 0 && (
                        <div className="property-stay-thumbnail">
                          <img
                            className="property-stay-img"
                            src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${propertyDetails.mediaFolder.files[0].fileName}`}
                          />
                          <button
                            className="carousel-toggle-button"
                            onClick={toggleCarousel}
                          >
                            View more...
                          </button>
                        </div>
                      )
                    : roomTypes.map((coverPic: any) => {
                        return (
                          <img
                            className="property-stay-img"
                            src={coverPic.roomType.images[0].imageUrl}
                          />
                        );
                      })}
                  <div className="carousel-container">
                    <button
                      className="carousel-arrow left"
                      disabled={startIndex == 0}
                      onClick={prevImages}
                    >
                      <img
                        src={leftArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>

                    <div className="carousel-images">
                      {/* Add your carousel images here */}
                      {propertyDetails?.mediaFolder?.files.length > 0 &&
                        propertyDetails?.mediaFolder?.files
                          .slice(startIndex, startIndex + 3)
                          .map((file: any, index: any) => {
                            return (
                              <img
                                key={index}
                                src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`}
                                alt={file.fileName}
                                onClick={(e) => {
                                  const imgIndex =
                                    propertyDetails?.mediaFolder?.files?.findIndex(
                                      (fileObj: { fileName: any }) =>
                                        fileObj.fileName === file.fileName
                                    );
                                  setImageIndex(imgIndex);
                                  setShowCarousel(!showCarousel);
                                }}
                              />
                            );
                          })}
                    </div>

                    <button
                      className="carousel-arrow right"
                      disabled={
                        startIndex + 3 >=
                        propertyDetails?.mediaFolder?.files.length
                      }
                      onClick={() => {
                        nextImages(propertyDetails.mediaFolder?.files.length);
                      }}
                    >
                      <img
                        src={rightArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>
                  </div>
                </Grid>

                {/* Second Child */}
                <Grid item xs={12} md={7} xl={8}>
                  <div className="property-stay-content-button-resp">
                    <div className="property-stay-div-resp">
                      <div className="property-stay-header-div">
                        <div className="property-stay-header-txt">
                          <h1 className="title">{propertyDetails.name}</h1>
                        </div>
                        <div className="property-stay-details-location-date">
                          <div className="property-stay-details-bubble">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 4,
                              }}
                            >
                              <LocationOnIcon
                                sx={{ height: "16px", color: "#7f7f7f" }}
                              />
                              <p
                                className="stay-location-date-label"
                                style={{ marginTop: 0, flexShrink: 0 }}
                              >
                                location:
                              </p>
                            </div>
                            <span className="stay-location-date-div">
                              {propertyDetails?.address}
                            </span>
                          </div>
                          {propertyDetails?.accommodationType &&
                            propertyDetails?.accommodationType !== null &&
                            propertyDetails?.accommodationType !== "" && (
                              <div
                                className="property-stay-details-bubble"
                                style={{ padding: "3px 12px" }}
                              >
                                <span className="pckg-type">
                                  Type:{" "}
                                  {getValueAccomodationType(
                                    propertyDetails?.accommodationType
                                  )}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{ textAlign: "justify" }}
                          className="property-stay-description test1"
                          dangerouslySetInnerHTML={{
                            __html:
                              propertyDetails?.descriptionText?.length > 350
                                ? propertyDetails?.descriptionText?.substring(
                                    0,
                                    singleItenaryDescription
                                  )
                                : propertyDetails?.descriptionText,
                          }}
                        />
                        {propertyDetails?.descriptionText?.length > 350 && (
                          <button
                            style={{
                              alignSelf: "flex-start",
                              fontSize: "14px",
                              color: "#2e8b98",
                              cursor: "pointer",
                              border: "none",
                              backgroundColor: "transparent",
                              padding: "3px 0px",
                            }}
                            onClick={() => {
                              if (singleItenaryDescription === 350) {
                                setSingleItenaryDescription(
                                  propertyDetails.descriptionText.length
                                );
                              } else {
                                setSingleItenaryDescription(350);
                              }
                            }}
                          >
                            {singleItenaryDescription === 350
                              ? "View more"
                              : "View less"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </ThemeProvider>
            <div className="property-facilities">
              <ul className="inline-block">
                {_.compact(propertyDetails?.facilities).length > 0 &&
                  _.compact(propertyDetails?.facilities)
                    ?.slice(0, facilityLength)
                    .map((item: any, index: number) => {
                      return (
                        <li
                          style={{ fontSize: "14px" }}
                          key={`package_hotel_facilities_${index}`}
                        >
                          <span style={{ width: "20px" }}>
                            <img
                              src={getIcon(item?.name)}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          {item?.name}&nbsp;
                          <CommonTooltip
                            title={`${
                              item?.extractedJson?.toBePaid
                                ? "This facility can have an additional charge to be paid at the property"
                                : ""
                            }`}
                            arrow
                          >
                            <span>
                              {item?.extractedJson?.toBePaid && "($)"}
                            </span>
                          </CommonTooltip>
                        </li>
                      );
                    })}
                {_.compact(propertyDetails?.facilities).length === 0 && (
                  <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                )}
                {propertyDetails?.mediaFolder?.files.length ===
                  facilityLength &&
                propertyDetails?.id === selectedviewmoreid &&
                propertyDetails?.facilities?.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(4, propertyDetails.id);
                    }}
                  >
                    view less
                  </li>
                ) : propertyDetails?.facilities?.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(
                        propertyDetails.mediaFolder?.files.length,
                        propertyDetails.id
                      );
                    }}
                  >
                    view more
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            {roomDetails && (
              <div className="room-child-wrapper">
                <div className="room-info-resp">
                  <div>
                    <RoomTypesContainer
                      imageArr={getImageArrForRoom(roomDetails)}
                    />
                  </div>
                  <div className="room-details-div">
                    <div className="room-header">{roomDetails.name}</div>
                    <div className="property-facilities-stays">
                      {roomDetails?.amenities
                        ?.slice(0, singleRoomAmentities)
                        .map(
                          (
                            amenity: { name: string; amenityType: string },
                            index: number
                          ) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                }}
                                key={`package_room_amenities_${index}`}
                                className="stays-item"
                              >
                                <span style={{ width: "20px" }}>
                                  <img
                                    src={getIcon(amenity.amenityType)}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                                <div>{amenity.name}</div>
                              </div>
                            );
                          }
                        )}
                      {roomDetails?.amenities?.length > 4 && (
                        <button
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: "#2e8b98",
                            border: "none",
                            backgroundColor: "transparent",
                            padding: "0",
                          }}
                          onClick={() =>
                            setSingleRoomAmentities(
                              roomDetails?.amenities?.length
                            )
                          }
                        >
                          {roomDetails?.amenities?.length > singleRoomAmentities
                            ? "View more"
                            : "View less"}
                        </button>
                      )}
                      {roomDetails?.amenities?.length % 2 !== 0 && (
                        <div className="empty-item"></div>
                      )}
                    </div>
                  </div>

                  <div className="meal-wrapper">
                    <div className="room-count-div">
                      <div className="dropdown-label">Meals:</div>
                      <select
                        disabled={true}
                        className="room-type-dropdown"
                        style={{ backgroundPosition: "195px" }}
                        value={mealPlan ?? ""}
                      >
                        {propertyDetails?.mealPlanList &&
                          propertyDetails?.mealPlanList?.length > 0 &&
                          propertyDetails?.mealPlanList
                            ?.find(
                              (mealPlan: { roomTypeId: any }) =>
                                mealPlan.roomTypeId === roomDetails.id
                            )
                            .mealPlan?.map((mealPlanName: any) => {
                              return (
                                <option value={mealPlanName.code}>
                                  {mealPlanName.dexcription}
                                </option>
                              );
                            })}
                      </select>
                    </div>
                    <div className="room-count-div">
                      <div className="dropdown-label">Rooms:</div>
                      <select
                        className="room-type-dropdown"
                        disabled={true}
                        value={roomCount}
                        style={{
                          width: "100px",
                          backgroundPosition: "195px",
                        }}
                      >
                        <option value="">Select number of rooms</option>
                        <option value={1}>{"1 Room"}</option>
                        <option value={2}>{"2 Rooms"}</option>
                        <option value={3}>{"3 Rooms"}</option>
                        <option value={4}>{"4 Rooms"}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {propertyDetails && (
          <div className="stays-section">
            <ThemeProvider theme={theme}>
              <Grid container spacing={2}>
                {/* First Child */}
                <Grid item xs={12} md={5} xl={4}>
                  {roomTypes.length == 0
                    ? propertyDetails?.mediaFolder?.files.length > 0 && (
                        <div className="property-stay-thumbnail">
                          <img
                            className="property-stay-img"
                            src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${propertyDetails.mediaFolder.files[0].fileName}`}
                          />
                          <button
                            className="carousel-toggle-button"
                            onClick={toggleCarousel}
                          >
                            View more...
                          </button>
                        </div>
                      )
                    : roomTypes.map((coverPic: any) => {
                        return (
                          <img
                            className="property-stay-img"
                            src={coverPic.roomType.images[0].imageUrl}
                          />
                        );
                      })}
                  <div className="carousel-container">
                    <button
                      className="carousel-arrow left"
                      disabled={startIndex == 0}
                      onClick={prevImages}
                    >
                      <img
                        src={leftArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>

                    <div className="carousel-images">
                      {/* Add your carousel images here */}
                      {propertyDetails?.mediaFolder?.files.length > 0 &&
                        propertyDetails?.mediaFolder?.files
                          .slice(startIndex, startIndex + 3)
                          .map((file: any, index: any) => {
                            return (
                              <img
                                key={index}
                                src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`}
                                alt={file.fileName}
                                onClick={(e) => {
                                  const imgIndex =
                                    propertyDetails?.mediaFolder?.files?.findIndex(
                                      (fileObj: { fileName: any }) =>
                                        fileObj.fileName === file.fileName
                                    );
                                  setImageIndex(imgIndex);
                                  setShowCarousel(!showCarousel);
                                }}
                              />
                            );
                          })}
                    </div>

                    <button
                      className="carousel-arrow right"
                      disabled={
                        startIndex + 3 >=
                        propertyDetails?.mediaFolder?.files.length
                      }
                      onClick={() => {
                        nextImages(propertyDetails.mediaFolder?.files.length);
                      }}
                    >
                      <img
                        src={rightArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>
                  </div>
                </Grid>

                {/* Second Child */}
                <Grid item xs={12} md={7} xl={8}>
                  <div className="property-stay-content-button">
                    <div className="property-stay-div">
                      <div className="property-stay-header-div">
                        <div className="property-stay-header-txt">
                          <h1 className="title">{propertyDetails.name}</h1>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            className="city-date-div"
                            style={{ marginTop: "4px" }}
                          >
                            {cityName}
                          </div>
                          {propertyDetails?.type !== null &&
                            propertyDetails?.type !== "" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                {(() => {
                                  const formattedDescription =
                                    formatPropertyDescription(
                                      propertyDetails?.type
                                    );

                                  if (!formattedDescription) return null;

                                  const descriptionText =
                                    formattedDescription.length > 1
                                      ? formattedDescription.replace(
                                          /\s\d+$/,
                                          ""
                                        )
                                      : null;

                                  const ratingValue =
                                    formattedDescription.length === 1
                                      ? Number(formattedDescription)
                                      : Number(
                                          formattedDescription.match(/\d+/)?.[0]
                                        ) || 0;

                                  return (
                                    <>
                                      {descriptionText && (
                                        <span style={{ fontSize: "14px" }}>
                                          {descriptionText}
                                        </span>
                                      )}
                                      <Rating value={ratingValue} readOnly />
                                    </>
                                  );
                                })()}
                              </div>
                            )}
                        </div>
                        <div className="property-stay-details-location-date">
                          <div className="property-stay-details-bubble">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 4,
                              }}
                            >
                              <LocationOnIcon
                                sx={{ height: "16px", color: "#7f7f7f" }}
                              />
                              <p
                                className="stay-location-date-label"
                                style={{ marginTop: 0, flexShrink: 0 }}
                              >
                                location:
                              </p>
                            </div>
                            <span className="stay-location-date-div">
                              {propertyDetails?.address}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{ textAlign: "justify" }}
                          className="property-stay-description test1"
                          dangerouslySetInnerHTML={{
                            __html:
                              propertyDetails?.descriptionText?.length > 350
                                ? propertyDetails?.descriptionText?.substring(
                                    0,
                                    singleItenaryDescription
                                  )
                                : propertyDetails?.descriptionText,
                          }}
                        />
                        {propertyDetails?.descriptionText?.length > 350 && (
                          <button
                            style={{
                              alignSelf: "flex-start",
                              fontSize: "14px",
                              color: "#2e8b98",
                              cursor: "pointer",
                              border: "none",
                              backgroundColor: "transparent",
                              padding: "3px 0px",
                            }}
                            onClick={() => {
                              if (singleItenaryDescription === 350) {
                                setSingleItenaryDescription(
                                  propertyDetails.descriptionText.length
                                );
                              } else {
                                setSingleItenaryDescription(350);
                              }
                            }}
                          >
                            {singleItenaryDescription === 350
                              ? "View more"
                              : "View less"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </ThemeProvider>
            <div className="property-facilities">
              <ul className="inline-block">
                {_.compact(propertyDetails?.facilities).length > 0 &&
                  _.compact(propertyDetails?.facilities)
                    ?.slice(0, facilityLength)
                    .map((item: any, index: number) => {
                      return (
                        <li
                          style={{ fontSize: "14px" }}
                          key={`package_hotel_facilities_${index}`}
                        >
                          <span style={{ width: "20px" }}>
                            <img
                              src={getIcon(item?.name)}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          {item?.name}&nbsp;
                          <CommonTooltip
                            title={`${
                              item?.extractedJson?.toBePaid
                                ? "This facility can have an additional charge to be paid at the property"
                                : ""
                            }`}
                            arrow
                          >
                            <span>
                              {item?.extractedJson?.toBePaid && "($)"}
                            </span>
                          </CommonTooltip>
                        </li>
                      );
                    })}
                {_.compact(propertyDetails?.facilities).length === 0 && (
                  <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                )}
                {propertyDetails?.mediaFolder?.files.length ===
                  facilityLength &&
                propertyDetails?.id === selectedviewmoreid &&
                propertyDetails?.facilities?.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(4, propertyDetails.id);
                    }}
                  >
                    view less
                  </li>
                ) : propertyDetails?.facilities?.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    onClick={() => {
                      moreFacilitylist(
                        propertyDetails.mediaFolder?.files.length,
                        propertyDetails.id
                      );
                    }}
                  >
                    view more
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <>
              {roomDetails && (
                <div className="room-info">
                  <div></div>
                  <div style={{ width: "226px", display: "flex" }}>
                    <RoomTypesContainer
                      imageArr={getImageArrForRoom(roomDetails)}
                    />
                  </div>
                  <div className="room-details-div">
                    <div className="room-header">{roomDetails.name}</div>

                    <div className="facilities-room-div">
                      {roomDetails?.amenities
                        ?.slice(0, singleRoomAmentities)
                        .map(
                          (
                            amenity: {
                              name: string;
                              amenityType: string;
                              extractedJson: any;
                            },
                            index: number
                          ) => {
                            return (
                              <>
                                <span
                                  key={`package_room_amenities_${index}`}
                                  style={{
                                    width: "14px",
                                  }}
                                >
                                  <img
                                    src={getIcon(amenity.amenityType)}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                                <span>{amenity.name}&nbsp;</span>
                                <CommonTooltip
                                  title={`${
                                    amenity?.extractedJson?.toBePaid
                                      ? "This amenity can have an additional charge to be paid at the property"
                                      : ""
                                  }`}
                                  arrow
                                >
                                  <span>
                                    {amenity?.extractedJson?.toBePaid && "($)"}
                                  </span>
                                </CommonTooltip>
                              </>
                            );
                          }
                        )}
                      {roomDetails?.amenities?.length > 4 && (
                        <button
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: "#2e8b98",
                            border: "none",
                            backgroundColor: "transparent",
                            padding: "0",
                          }}
                          onClick={() =>
                            setSingleRoomAmentities(
                              roomDetails.amenities.length
                            )
                          }
                        >
                          {roomDetails.amenities.length > singleRoomAmentities
                            ? "View more"
                            : "View less"}
                        </button>
                      )}
                      {roomDetails?.amenities?.length % 2 !== 0 && (
                        <div className="empty-item"></div>
                      )}
                    </div>
                  </div>

                  <div className="meal-wrapper">
                    <div className="room-count-div">
                      <div className="dropdown-label">Meals:</div>

                      <select
                        disabled={true}
                        className="room-type-dropdown"
                        style={{ backgroundPosition: "195px" }}
                        value={mealPlan ?? ""}
                      >
                        {" "}
                        {propertyDetails?.mealPlanList &&
                          propertyDetails?.mealPlanList?.length > 0 &&
                          propertyDetails?.mealPlanList
                            ?.find(
                              (mealPlan: { roomTypeId: any }) =>
                                mealPlan.roomTypeId === roomDetails.id
                            )
                            .mealPlan?.map((mealPlanName: any) => {
                              return (
                                <option value={mealPlanName.code}>
                                  {mealPlanName.dexcription}
                                </option>
                              );
                            })}
                      </select>
                    </div>
                    <div className="room-count-div">
                      <div className="dropdown-label">Rooms:</div>
                      <select
                        className="room-type-dropdown"
                        disabled={true}
                        value={roomCount}
                        style={{
                          width: "100px",
                          backgroundPosition: "195px",
                        }}
                      >
                        <option value="">Select number of rooms</option>
                        <option value={1}>{"1 Room"}</option>
                        <option value={2}>{"2 Rooms"}</option>
                        <option value={3}>{"3 Rooms"}</option>
                        <option value={4}>{"4 Rooms"}</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
        {showCarousel && (
          <ImageGalleryPopup
            packageData={imageOverLayArray}
            showImageGalleryPopup={showCarousel}
            updateShowImageGalleryPopup={updateShowImageGalleryPopup}
          />
        )}
        {showCarousel && (
          <ImageGalleryPopup
            packageData={imageOverLayArray}
            showImageGalleryPopup={showCarousel}
            updateShowImageGalleryPopup={updateShowImageGalleryPopup}
            imageIndex={imageIndex}
          />
        )}
      </>
    );
  }
};

export default ItineraryStaysView;
