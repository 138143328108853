import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { Bars } from "react-loader-spinner";
import ReactPixel from "react-facebook-pixel";
import { getItineraryListing } from "../../store/itinerary/actions";
import ItineraryCard from "./ItineraryCard";
import { Pagination } from "../../components/pagination-tray/Pagination";

const MyItineraries = () => {
  let dispatch = useDispatch();
  const [itineraryListData, setItineraryListData] = useState<any[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const { response, loader } = useSelector(
    (state: any) => state.ItineraryListingReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    dispatch(getItineraryListing(currentPageNumber));
  }, [dispatch, currentPageNumber]);

  useEffect(() => {
    if (response) {
      setItineraryListData(response.itineraryPlans);
    }
  }, [response, currentPageNumber]);

  const handlePageChange = (action: any) => {
    window.scrollTo({
      top: 0,
    });
    sessionStorage.removeItem("scrollPosition");
    let _pNumb = currentPageNumber;
    switch (true) {
      case action === "prev":
        setCurrentPageNumber(currentPageNumber - 1);
        _pNumb--;
        break;
      case action === "next":
        setCurrentPageNumber(currentPageNumber + 1);
        _pNumb++;
        break;
      case !isNaN(action):
        setCurrentPageNumber(action);
        _pNumb = action;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="my-account-content">
        <div className="my-booking-blk">
          {itineraryListData &&
            itineraryListData !== undefined &&
            itineraryListData.length > 0 &&
            itineraryListData?.map((item: any, index: number) => {
              return (
                <ItineraryCard item={item} key={index} itineraryIndex={index} />
              );
            })}
          {itineraryListData &&
            itineraryListData !== undefined &&
            itineraryListData.length === 0 && (
              <div className="info-txt">No record found</div>
            )}
        </div>
        {itineraryListData &&
          itineraryListData !== undefined &&
          itineraryListData.length > 0 && (
            <div>
              <Pagination
                urlQueryParams={""}
                pathName={""}
                onPageChange={handlePageChange}
                currentPage={currentPageNumber}
                totalRecords={response.recordCount}
                listCount={response.pageSize}
                isPathsEnabled={false}
              />
            </div>
          )}
      </div>
      {loader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
};

export default MyItineraries;
