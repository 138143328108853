import _ from "underscore";
import cuid from "cuid";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./CustomItinerary.scss";
import {
  CityModel,
  Interests,
  PlaceToVisit,
  PlaceToVisitActivity,
  QuoteDaywiseItineraryModel,
  initVisitDestinationModel,
} from "src/models/models";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import { experienceApiAxios } from "src/helpers/api_helper";
import { DESTINATIONS, EVENTS, ITINERARY } from "src/helpers/url_helper";
import { RemoveDestinationMinus, StepperIcon } from "src/icons/coreIcons";
import {
  TransportMode,
  getTimeInMeridianFormat,
  haversineDistance,
} from "src/helpers/utils";
import ItineraryPlacesToVisitView from "./ItineraryPlacesToVisitView";
import ItineraryStaysView from "./ItineraryStaysView";
import { getHeader } from "src/helpers/backend_helper";

const CustomItineraryView = () => {
  const params = useParams();
  const [adultCount, setAdultCount] = useState(2);
  const [childCount, setChildCount] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [destinationId, setDestinationId] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [customItineraryStartDate, setCustomItineraryStartDate] =
    useState<any>();
  const [cityList, setCityList] = useState<any[]>([]);

  const fetchModeFromItineraryRoute = (
    fromDestId: number,
    toDestId: number,
    itineraryRoutes: any[]
  ) => {
    let transportModeForRoute = "transportMode.Car";
    itineraryRoutes.forEach((route) => {
      if (
        route.fromDestinationId === fromDestId &&
        route.toDestinationId === toDestId
      ) {
        transportModeForRoute = route.transportMode;
      }
    });
    return transportModeForRoute;
  };

  const populateTransportAndTime = (
    fromId: number,
    toId: number,
    fromIndex: number,
    toIndex: number
  ) => {
    let transportModeForRoute = "transportMode.Car";
    itineraryPlanStore.itineraryRoutes.forEach((route) => {
      if (
        route.fromDestinationId === fromId &&
        route.toDestinationId === toId
      ) {
        transportModeForRoute = route.transportMode;
      }
    });

    return `${
      TransportMode.find((mode) => mode.code === transportModeForRoute)
        ?.description
    } - ${getTimeCalculation(transportModeForRoute, fromIndex, toIndex)}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Custom Itinerary | Voyaah";
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");

    const postCustomItineraryEvent = async () => {
      const localStorageData = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (localStorageData) {
        customerProfileId = JSON.parse(localStorageData!).id;

        const pageVisitObj = {
          eventType: "PAGEVISIT",
          payload: {
            type: "PAGEVISIT",
            detailsJson: {
              pageType: "custom-itinerary",
              pageUrl: `/custom-itinerary`,
              payload: {},
            },
            customerProfileId: customerProfileId,
          },
        };

        await postEvent(pageVisitObj);
      }
    };

    postCustomItineraryEvent();

    let activeVoyaahCityList: any[] = [];

    const fetchItineraryData = async () => {
      itineraryPlanStore.setStateToInitialForm();
      const headerResponse = await getHeader();
      const itineraryData = await experienceApiAxios.get(
        `${ITINERARY}/${params.id}`,
        {
          headers: headerResponse,
        }
      );

      if (itineraryData.status === 200) {
        const activeCities = await experienceApiAxios.get(
          `${DESTINATIONS}?isActive=true`,
          {
            headers: headerResponse,
          }
        );

        if (activeCities.status === 200) {
          activeVoyaahCityList = activeCities.data;
        }
        itineraryPlanStore.setPlacesToVisitEdit(false);
        clearDataAfterConfirmation();
        const itineraryObj = itineraryData.data.itineraryPlans[0];
        const itineraryRoutesArr =
          itineraryData.data?.itineraryRoutes[0]?.routes;

        itineraryPlanStore.updateSelectedItineraryPlan({
          ...itineraryPlanStore.selectedItineraryPlan,
          name: itineraryObj.name,
          id: itineraryObj.id,
          arrivalAirportName: itineraryObj.arrivalAirport.name,
          departureAirportName: itineraryObj.departureAirport.name,
          arrivalAirportId: itineraryObj.arrivalAirport.id,
          departureAirportId: itineraryObj.departureAirport.id,
        });

        itineraryPlanStore.setOpportunityStays(itineraryObj.opportunitystays);

        setAdultCount(itineraryObj?.extractedJson?.numAdults);
        setChildCount(itineraryObj?.extractedJson?.numChildren);
        setStartDate(itineraryObj?.extractedJson?.fromDate);
        setEndDate(itineraryObj?.extractedJson?.toDate);
        setCustomItineraryStartDate(
          itineraryObj?.extractedJson?.fromDateObject
        );

        updateItineraryDestinationFromVoyaahList(
          "departure",
          activeVoyaahCityList
        );
        updateItineraryDestinationFromVoyaahList(
          "arrival",
          activeVoyaahCityList
        );

        const visitDestinations: any[] = itineraryObj.visitDestinations;

        itineraryPlanStore.updateDestinationList(
          itineraryPlanStore.visitDestinationList[0].id,
          {
            ...itineraryPlanStore.visitDestinationList[0],
            stayDuration: visitDestinations[0].stayDuration,
          }
        );

        itineraryPlanStore.updateDestinationList(
          itineraryPlanStore.visitDestinationList[
            itineraryPlanStore.visitDestinationList.length - 1
          ].id,
          {
            ...itineraryPlanStore.visitDestinationList[
              itineraryPlanStore.visitDestinationList.length - 1
            ],
            stayDuration:
              visitDestinations[visitDestinations.length - 1].stayDuration,
          }
        );

        visitDestinations.forEach((visitDestination, index) => {
          if (index !== 0 && index != visitDestinations.length - 1) {
            itineraryPlanStore.addNewDestination(
              initVisitDestinationModel({
                itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
                index: itineraryPlanStore.visitDestinationList.length + 1,
                id: Math.floor(Math.random() * 100) * -1,
                stayDuration: visitDestination.stayDuration,
                city: visitDestination.city,
                cityId: visitDestination.city.id,
                cityName: visitDestination.city.name,
              })
            );
          }
        });

        visitDestinations.forEach((destination, index) => {
          if (index > 0) {
            updateTransportMode(
              index - 1,
              index,
              fetchModeFromItineraryRoute(
                visitDestinations[index - 1].id,
                destination.id,
                itineraryRoutesArr
              )
            );
          }
        });

        const daywiseItineraryArr: QuoteDaywiseItineraryModel[] =
          itineraryObj.daywiseItinerary;

        daywiseItineraryArr.forEach((daywiseItinerary) => {
          const cityList: {
            cityId: number;
            cityName: string;
            city?: CityModel;
          }[] = [];
          const activities: PlaceToVisitActivity[] = [];
          daywiseItinerary.itineraryTimeSlots?.forEach((itineraryTimeSlot) => {
            const interestsArr: any[] = [];
            let cityOfPlace: CityModel = {
              id: 0,
              name: "",
              cityCode: "",
              countryCode: "",
            };

            itineraryTimeSlot.itineraryPlaceSlot.forEach((slot: any, index) => {
              const interestObj: Interests = {
                imageUrl: "",
                placeId: slot.placeOfInterestId,
                label: slot.placeOfInterest.name,
                value: slot.placeOfInterest.name,
                external: true,
                latitude: slot.placeOfInterest.latitude,
                longitude: slot.placeOfInterest.longitude,
              };

              interestsArr.push(interestObj);

              if (index === 0) {
                cityOfPlace = slot.placeOfInterest.city;
              }
            });

            const additionalThingsToDo = itineraryTimeSlot.additionalThingsToDo;
            additionalThingsToDo?.forEach((thingsToDo) => {
              interestsArr.push({
                label: thingsToDo,
                value: thingsToDo,
                __isNew__: true,
              });
            });

            const activityObj: PlaceToVisitActivity = {
              interests: interestsArr,
              placeName: cityOfPlace?.name ?? "",
              from: getTimeInMeridianFormat(itineraryTimeSlot.startTime),
              to: getTimeInMeridianFormat(itineraryTimeSlot.endTime),
            };

            activities.push(activityObj);
            cityList.push({
              cityId: cityOfPlace.id,
              cityName: cityOfPlace.name,
              city: cityOfPlace,
            });
          });

          const seen = new Set();

          const newPlaceToVisit: PlaceToVisit = {
            id: cuid(),
            name: `Day ${daywiseItinerary.dayIndex} - ${daywiseItinerary.title}`,
            dayIndex: daywiseItinerary?.dayIndex ?? 1,
            positionIndex: getPositionIndex(),
            cityList: cityList.filter((city) => {
              const duplicate = seen.has(city.cityName);
              seen.add(city.cityName);
              return !duplicate;
            }),
            activities: activities,
          };

          itineraryPlanStore.addNewPlaceToVisit(newPlaceToVisit);
        });
      }
    };

    if (params.id) {
      fetchItineraryData();
    }
  }, []);

  const postEvent = async (eventBody: any) => {
    const headerResponse = await getHeader();
    const eventResponse = await experienceApiAxios.post(
      `${EVENTS}`,
      eventBody,
      {
        headers: headerResponse,
      }
    );

    if (eventResponse.status === 200) {
      return;
    }
  };

  const clearDataAfterConfirmation = () => {
    setAdultCount(0);
    setChildCount(0);
    setDestinationId(0);
    itineraryPlanStore.setStateToInitialForm();
  };

  const fetchDestinations = async (cityName: string) => {
    const headerResponse = await getHeader();
    experienceApiAxios
      .get(`${DESTINATIONS}?pageSize=40&currentPage=1&search=${cityName}`, {
        headers: headerResponse,
      })
      .then((response) => {
        setCityList(response.data);
      });
  };

  const updateItineraryDestinationFromVoyaahList = (
    dest: string,
    voyaahCityList: CityModel[]
  ) => {
    let updatedCityName =
      dest === "arrival"
        ? itineraryPlanStore.selectedItineraryPlan.arrivalAirportName
        : itineraryPlanStore.selectedItineraryPlan.departureAirportName;

    if (updatedCityName && updatedCityName.length > 2) {
      let filteredData = voyaahCityList.find(
        (x: any) => x?.name?.toLowerCase() === updatedCityName?.toLowerCase()
      );
      if (filteredData) {
        if (dest === "arrival") {
          itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            arrivalAirportId: filteredData.id,
            arrivalAirportName: filteredData?.name,
          });
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index:
                itineraryPlanStore.visitDestinationList.length === 1
                  ? 2
                  : itineraryPlanStore.visitDestinationList.length,
              id: Math.floor(Math.random() * 100) * -1,
              cityId: filteredData?.id,
              city: filteredData,
              cityName: filteredData?.name,
              stayDuration: 2,
            })
          );
        } else {
          itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            departureAirportId: filteredData.id,
            departureAirportName: filteredData?.name,
          });
          itineraryPlanStore.addNewDestination(
            initVisitDestinationModel({
              itineraryPlanId: itineraryPlanStore.selectedItineraryPlan.id,
              index: 1,
              id: Math.floor(Math.random() * 100) * -1,
              cityId: filteredData.id,
              city: filteredData,
              cityName: filteredData?.name,
              stayDuration: 2,
            })
          );
        }
      }
    } else {
      dest === "arrival"
        ? itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            arrivalAirportId: 0,
            arrivalAirportName: "",
          })
        : itineraryPlanStore.updateSelectedItineraryPlan({
            ...itineraryPlanStore.selectedItineraryPlan,
            departureAirportId: 0,
            departureAirportName: "",
          });
    }
  };

  const getDistanceOnly = (fromIndex: number, toIndex: number) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];

    let distanceInKms =
      fromDest?.city && toDest?.city
        ? haversineDistance(fromDest?.city, toDest?.city)
        : 0;
    return `${Math.ceil(distanceInKms)} kms`;
  };

  const getTimeCalculation = (
    transport: string,
    fromIndex: number,
    toIndex: number
  ) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];

    let distanceInKms =
      fromDest?.city && toDest?.city
        ? haversineDistance(fromDest?.city, toDest?.city)
        : 0;
    let distanceCalculated = ``;

    distanceCalculated +=
      distanceInKms /
        (TransportMode.find((x: any) => x.code === transport)?.averageSpeed ??
          1) >=
      1
        ? ` ${Math.ceil(
            distanceInKms /
              (TransportMode.find((x: any) => x.code === transport)
                ?.averageSpeed ?? 1)
          )} hours`
        : ` ${Math.ceil(
            (distanceInKms /
              (TransportMode.find((x: any) => x.code === transport)
                ?.averageSpeed ?? 1)) *
              60
          )} minutes`;

    return distanceCalculated;
  };

  const updateTransportMode = (
    fromIndex: number,
    toIndex: number,
    modeChange: string
  ) => {
    let fromDest = itineraryPlanStore.visitDestinationList[fromIndex];
    let toDest = itineraryPlanStore.visitDestinationList[toIndex];
    let existingRoute = itineraryPlanStore.itineraryRoutes.find(
      (x: any) =>
        x.fromDestinationId === fromDest.id && x.toDestinationId === toDest.id
    );

    if (existingRoute) {
      itineraryPlanStore.updateItineraryRoute(existingRoute.id, {
        ...existingRoute,
        transportMode: modeChange,
      });
    } else {
      itineraryPlanStore.addNewItineraryRoute(
        fromDest?.city && toDest?.city
          ? haversineDistance(fromDest?.city, toDest?.city)
          : 0,
        fromDest.id,
        toDest.id,
        modeChange ?? "transportMode.Car"
      );
    }
  };

  const getPositionIndex = () => {
    if (itineraryPlanStore.placesToVisit.length) {
      return (
        itineraryPlanStore.placesToVisit[
          itineraryPlanStore.placesToVisit.length - 1
        ].positionIndex + 1
      );
    }

    return 0;
  };

  useEffect(() => {
    let destinationData = itineraryPlanStore.visitDestinationList?.find(
      (x: any) => x.id === destinationId
    );
    if (destinationData) {
      const timeout = setTimeout(() => {}, 1000);
      return () => clearTimeout(timeout);
    }
  }, [itineraryPlanStore?.visitDestinationList]);

  useEffect(() => {
    if (itineraryPlanStore?.selectedItineraryPlan?.arrivalAirportName) {
      const timeout = setTimeout(() => {
        fetchDestinations(
          itineraryPlanStore?.selectedItineraryPlan?.arrivalAirportName ?? ""
        );
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [itineraryPlanStore?.selectedItineraryPlan?.arrivalAirportName]);

  useEffect(() => {
    if (itineraryPlanStore?.selectedItineraryPlan?.departureAirportName) {
      const timeout = setTimeout(() => {
        fetchDestinations(
          itineraryPlanStore?.selectedItineraryPlan?.departureAirportName ?? ""
        );
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [itineraryPlanStore?.selectedItineraryPlan?.departureAirportName]);

  useEffect(() => {}, [
    itineraryPlanStore.visitDestinationList,
    itineraryPlanStore.itineraryRoutes,
    itineraryPlanStore.stayList,
    itineraryPlanStore.selectedItineraryPlan,
    itineraryPlanStore.itineraryPlanRoutesList,
    destinationId,
  ]);

  useEffect(() => {}, [
    itineraryPlanStore.placesToVisit,
    itineraryPlanStore.propertyList,
  ]);

  return (
    <>
      <div className="inner-page custom-itinerary-page">
        <div className="section">
          <div className="ci-header-section">
            <div className="breadcrumb-section">
              <div className="breadcrum">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li style={{ color: "#000" }}>›</li>
                  <li style={{ color: "#000" }}>Custom Itinerary</li>
                </ul>
              </div>
            </div>

            {/* {screenWidth > 700 && ( */}
            <div className="inner-heading-div">
              <div className="heading-text-div">
                {!itineraryPlanStore.selectedItineraryPlanNameEdit && (
                  <div className="heading-edit-text-div">
                    <h3 className="head heading-txt" style={{ color: "#000" }}>
                      {itineraryPlanStore.selectedItineraryPlan.name}
                    </h3>
                  </div>
                )}
                <div className="arrival-departure-div">
                  <p
                    className="arrival-departure-text"
                    style={{ color: "#000" }}
                  >
                    {itineraryPlanStore.visitDestinationList &&
                      itineraryPlanStore.visitDestinationList.map(
                        (destination, index: number) => {
                          if (
                            index ===
                            itineraryPlanStore.visitDestinationList.length - 1
                          ) {
                            return destination.cityName;
                          } else {
                            return `${destination.cityName} - `;
                          }
                        }
                      )}
                  </p>
                </div>
                <div>
                  <p
                    className="arrival-departure-text"
                    style={{
                      color: "#000000",
                      fontWeight: 300,
                      fontSize: "13px",
                    }}
                  >
                    {`Itinerary Dates: ${startDate} - ${endDate}, ${adultCount} Adult(s) and ${childCount} Children   `}
                  </p>
                </div>
              </div>
            </div>
            {/* )} */}

            {/* {screenWidth < 700 && (
            <div className="inner-heading-div-resp">
              <div className="heading-text-div">
                {!itineraryPlanStore.selectedItineraryPlanNameEdit && (
                  <div className="heading-edit-text-div">
                    <h3 className="head heading-txt" style={{ color: "#000" }}>
                      {itineraryPlanStore.selectedItineraryPlan.name}
                    </h3>
                  </div>
                )}
                <div className="arrival-departure-div">
                  <p
                    className="arrival-departure-text"
                    style={{ color: "#000" }}
                  >
                    {itineraryPlanStore.visitDestinationList &&
                      itineraryPlanStore.visitDestinationList.map(
                        (destination, index: number) => {
                          if (
                            index ===
                            itineraryPlanStore.visitDestinationList.length - 1
                          ) {
                            return destination.cityName;
                          } else {
                            return `${destination.cityName} - `;
                          }
                        }
                      )}
                  </p>
                </div>
                <div>
                  <p
                    className="arrival-departure-text"
                    style={{
                      color: "#000000",
                      fontWeight: 300,
                      fontSize: "13px",
                    }}
                  >
                    {`Itinerary Dates: ${startDate} - ${endDate}, ${adultCount} Adult(s) and ${childCount} Children   `}
                  </p>
                </div>
              </div>
            </div>
          )} */}
          </div>
        </div>

        {/* {screenWidth > 700 && */}
        {itineraryPlanStore.visitDestinationList &&
          itineraryPlanStore.visitDestinationList.length > 0 && (
            <div className="outer-itinerary-div">
              <div className="section" style={{ flexGrow: 1 }}>
                <div className="inner-itinerary-div">
                  <div className="arrival-departure-input-section">
                    <div className="departure-input-section">
                      <label
                        className="arrival-destination-div-label"
                        style={{ textAlign: "end" }}
                      >
                        Entry point
                      </label>
                      <input
                        className="departure-text"
                        type="text"
                        placeholder="Enter departure city"
                        id="itineraryPlan_txtArrival"
                        aria-describedby="name"
                        value={
                          itineraryPlanStore.selectedItineraryPlan
                            .departureAirportName || ""
                        }
                        list="departure-datalistOptions"
                        disabled={true}
                      />
                    </div>
                    <div className="arrival-input-section">
                      <label
                        className="arrival-destination-div-label"
                        style={{ textAlign: "end" }}
                      >
                        Exit point
                      </label>
                      <input
                        className="arrival-text"
                        type="text"
                        placeholder="Enter arrival city"
                        id="itineraryPlan_txtArrival"
                        aria-describedby="name"
                        list="arrival-datalistOptions"
                        value={
                          itineraryPlanStore.selectedItineraryPlan
                            .arrivalAirportName || ""
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="itinerary-planning-div">
                    <div className="itinerary-planning-cities">
                      <div className="itinerary-planning-cities-heading-div">
                        <h4 className="itinerary-planning-cities-heading">
                          Plan
                        </h4>
                      </div>

                      <div className="cities-list-div">
                        <DragDropContext onDragEnd={() => {}}>
                          <Droppable droppableId="Table">
                            {(provided) => (
                              <table
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <tbody>
                                  {itineraryPlanStore.visitDestinationList.map(
                                    (item, index) => {
                                      if (
                                        itineraryPlanStore.visitDestinationList
                                          .length === 2 &&
                                        itineraryPlanStore
                                          .visitDestinationList[0].cityName ===
                                          itineraryPlanStore
                                            .visitDestinationList[1].cityName &&
                                        index === 0
                                      ) {
                                        <Draggable
                                          key={item.id.toString()}
                                          draggableId={item.id.toString()}
                                          index={index}
                                          isDragDisabled={true}
                                        >
                                          {(provided) => (
                                            <tr
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <td className="col-sm-1"></td>
                                              <td
                                                className="col-sm-1"
                                                style={{
                                                  textAlign: "end",
                                                  padding: "5px",
                                                }}
                                              >
                                                <button
                                                  className="btn btn-icon"
                                                  style={{
                                                    marginRight: "2px",
                                                    border: "none",
                                                    background: "none",
                                                    color: "black",
                                                  }}
                                                  id={`opp_routing${index}_btnDelete`}
                                                  disabled={
                                                    index === 0 ||
                                                    index ===
                                                      itineraryPlanStore
                                                        .visitDestinationList
                                                        .length -
                                                        1
                                                  }
                                                  onClick={() =>
                                                    itineraryPlanStore.deleteDestination(
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  <RemoveDestinationMinus />
                                                </button>
                                              </td>
                                              <td
                                                className="col-sm-3 col-md-3 col-lg-3"
                                                style={{ padding: "5px" }}
                                              >
                                                <input
                                                  type="text"
                                                  id={`opp_routing${index}_txtDest`}
                                                  className={`cityname-itinerary`}
                                                  aria-describedby="propertyZipCode"
                                                  disabled={true}
                                                  value={item?.cityName}
                                                  list={`datalistOptions${index}`}
                                                  placeholder={`Enter Destination`}
                                                />
                                              </td>
                                              <td
                                                className="col-sm-2"
                                                style={{ padding: "5px" }}
                                              >
                                                <select
                                                  className={`nights-itinerary`}
                                                  id={`opp_routing${index}_selDuration`}
                                                  disabled={true}
                                                  value={item.stayDuration}
                                                >
                                                  {[
                                                    0, 1, 2, 3, 4, 5, 6, 7, 8,
                                                    9, 10, 11, 12, 13, 14, 15,
                                                    16, 17, 18, 19, 20,
                                                  ].map((item: any) => {
                                                    return (
                                                      <option
                                                        key={`numNights${item}`}
                                                        value={item}
                                                      >
                                                        {`${item} Nights`}
                                                      </option>
                                                    );
                                                  })}
                                                </select>
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>;
                                      } else {
                                        return (
                                          <Draggable
                                            key={item.id.toString()}
                                            draggableId={item.id.toString()}
                                            index={index}
                                            isDragDisabled={true}
                                          >
                                            {(provided) => (
                                              <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <td className="col-sm-1"></td>
                                                <td
                                                  className="col-sm-1"
                                                  style={{
                                                    textAlign: "end",
                                                    padding: "5px",
                                                  }}
                                                >
                                                  <button
                                                    className="btn btn-icon"
                                                    style={{
                                                      marginRight: "2px",
                                                      border: "none",
                                                      background: "none",
                                                      color: "black",
                                                    }}
                                                    id={`opp_routing${index}_btnDelete`}
                                                    disabled={
                                                      index === 0 ||
                                                      index ===
                                                        itineraryPlanStore
                                                          .visitDestinationList
                                                          .length -
                                                          1
                                                    }
                                                    onClick={() =>
                                                      itineraryPlanStore.deleteDestination(
                                                        item.id
                                                      )
                                                    }
                                                  >
                                                    <RemoveDestinationMinus />
                                                  </button>
                                                </td>
                                                <td
                                                  className="col-sm-3 col-md-3 col-lg-3"
                                                  style={{ padding: "5px" }}
                                                >
                                                  <input
                                                    type="text"
                                                    id={`opp_routing${index}_txtDest`}
                                                    className={`cityname-itinerary`}
                                                    aria-describedby="propertyZipCode"
                                                    disabled={true}
                                                    value={item?.cityName}
                                                    list={`datalistOptions${index}`}
                                                    placeholder={`Enter Destination`}
                                                  />
                                                </td>
                                                <td
                                                  className="col-sm-2"
                                                  style={{ padding: "5px" }}
                                                >
                                                  <select
                                                    className={`nights-itinerary`}
                                                    id={`opp_routing${index}_selDuration`}
                                                    disabled={true}
                                                    value={item.stayDuration}
                                                  >
                                                    {[
                                                      0, 1, 2, 3, 4, 5, 6, 7, 8,
                                                      9, 10, 11, 12, 13, 14, 15,
                                                      16, 17, 18, 19, 20,
                                                    ].map((item: any) => {
                                                      return (
                                                        <option
                                                          key={`numNights${item}`}
                                                          value={item}
                                                        >
                                                          {`${item} Nights`}
                                                        </option>
                                                      );
                                                    })}
                                                  </select>
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    }
                                  )}
                                  {provided.placeholder}
                                </tbody>
                              </table>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </div>
                    <div className="itinerary-planning-distances">
                      <h4 className="itinerary-planning-distances-heading">
                        Transfers (
                        {itineraryPlanStore.visitDestinationList.length === 0
                          ? 0
                          : itineraryPlanStore.visitDestinationList.length - 1}
                        )
                      </h4>
                      <div className="transfer-data-div">
                        {itineraryPlanStore.visitDestinationList.map(
                          (item, index) => {
                            if (
                              itineraryPlanStore.visitDestinationList.length ===
                                2 &&
                              itineraryPlanStore.visitDestinationList[0]
                                .cityName ===
                                itineraryPlanStore.visitDestinationList[1]
                                  .cityName
                            ) {
                              return <></>;
                            } else {
                              return (
                                <div key={index}>
                                  {index > 0 && (
                                    <div className="transfer-step">
                                      <div>
                                        <StepperIcon />
                                      </div>
                                      <div className="city-distance-transport-div-view">
                                        <div className="city-distance-div">
                                          <p className="city-link">{`${
                                            itineraryPlanStore
                                              .visitDestinationList[index - 1]
                                              .cityName
                                          }-${
                                            itineraryPlanStore
                                              .visitDestinationList[index]
                                              .cityName
                                          }`}</p>

                                          <p className="distance-time-link">
                                            {getDistanceOnly(index - 1, index)}
                                          </p>
                                        </div>
                                        <input
                                          className="transfermode-itinerary"
                                          readOnly
                                          value={populateTransportAndTime(
                                            itineraryPlanStore
                                              .visitDestinationList[index - 1]
                                              .id,
                                            itineraryPlanStore
                                              .visitDestinationList[index].id,
                                            index - 1,
                                            index
                                          )}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {/* )} */}

        {/* {screenWidth < 700 &&
          itineraryPlanStore.visitDestinationList &&
          itineraryPlanStore.visitDestinationList.length > 0 && (
            <div className="outer-itinerary-div-resp">
              <div className="inner-itinerary-div-resp">
                <div className="arrival-departure-input-section-resp">
                  <div className="departure-input-section-resp">
                    <label
                      className="arrival-destination-div-label"
                      style={{ textAlign: "end" }}
                    >
                      Entry point
                    </label>
                    <input
                      className="departure-text"
                      type="text"
                      placeholder="Enter departure city"
                      id="itineraryPlan_txtArrival"
                      aria-describedby="name"
                      value={
                        itineraryPlanStore.selectedItineraryPlan
                          .departureAirportName || ""
                      }
                      list="departure-datalistOptions"
                      disabled={true}
                    />
                  </div>
                  <div className="arrival-input-section-resp">
                    <label
                      className="arrival-destination-div-label"
                      style={{ textAlign: "end" }}
                    >
                      Exit point
                    </label>
                    <input
                      className="arrival-text"
                      type="text"
                      placeholder="Enter arrival city"
                      id="itineraryPlan_txtArrival"
                      aria-describedby="name"
                      list="arrival-datalistOptions"
                      value={
                        itineraryPlanStore.selectedItineraryPlan
                          .arrivalAirportName || ""
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="itinerary-planning-div-resp">
                  <div className="itinerary-planning-cities-resp">
                    <div className="itinerary-planning-cities-heading-div">
                      <h4 className="itinerary-planning-cities-heading">
                        Plan
                      </h4>
                    </div>

                    <div className="cities-list-div">
                      <DragDropContext onDragEnd={() => {}}>
                        <Droppable droppableId="Table">
                          {(provided) => (
                            <table
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <tbody>
                                {itineraryPlanStore.visitDestinationList.map(
                                  (item, index) => (
                                    <Draggable
                                      key={item.id.toString()}
                                      draggableId={item.id.toString()}
                                      index={index}
                                      isDragDisabled={true}
                                    >
                                      {(provided) => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <td
                                            className="col-sm-3 col-md-3 col-lg-3"
                                            style={{ padding: "5px" }}
                                          >
                                            <input
                                              type="text"
                                              id={`opp_routing${index}_txtDest`}
                                              className={`cityname-itinerary`}
                                              aria-describedby="propertyZipCode"
                                              disabled={true}
                                              value={item?.cityName}
                                              list={`datalistOptions${index}`}
                                              placeholder={`Enter Destination`}
                                            />
                                          </td>
                                          <td
                                            className="col-sm-2"
                                            style={{ padding: "5px" }}
                                          >
                                            <select
                                              className={`nights-itinerary`}
                                              id={`opp_routing${index}_selDuration`}
                                              disabled={true}
                                              value={item.stayDuration}
                                            >
                                              {[
                                                0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
                                                10, 11, 12, 13, 14, 15, 16, 17,
                                                18, 19, 20,
                                              ].map((item: any) => {
                                                return (
                                                  <option
                                                    key={`numNights${item}`}
                                                    value={item}
                                                  >
                                                    {`${item} Nights`}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </tbody>
                            </table>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                  <div className="itinerary-planning-distances-resp">
                    <h4 className="itinerary-planning-distances-heading">
                      Transfers (
                      {itineraryPlanStore.visitDestinationList.length === 0
                        ? 0
                        : itineraryPlanStore.visitDestinationList.length - 1}
                      )
                    </h4>
                    <div className="transfer-data-div">
                      {itineraryPlanStore.visitDestinationList.map(
                        (item, index) => {
                          return (
                            <div key={index}>
                              {index > 0 && (
                                <div className="transfer-step">
                                  <div>
                                    <StepperIcon />
                                  </div>
                                  <div className="city-distance-transport-div-view-resp">
                                    <div className="city-distance-div">
                                      <p className="city-link">{`${
                                        itineraryPlanStore.visitDestinationList[
                                          index - 1
                                        ].cityName
                                      }-${
                                        itineraryPlanStore.visitDestinationList[
                                          index
                                        ].cityName
                                      }`}</p>

                                      <p className="distance-time-link">
                                        {getDistanceOnly(index - 1, index)}
                                      </p>
                                    </div>
                                    <input
                                      className="transfermode-itinerary"
                                      readOnly
                                      value={populateTransportAndTime(
                                        itineraryPlanStore.visitDestinationList[
                                          index - 1
                                        ].id,
                                        itineraryPlanStore.visitDestinationList[
                                          index
                                        ].id,
                                        index - 1,
                                        index
                                      )}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

        {itineraryPlanStore.visitDestinationList &&
          itineraryPlanStore.visitDestinationList.length === 0 && (
            <div className="outer-itinerary-div-no-data">
              <div className="section" style={{ flexGrow: 1 }}>
                <div
                  className="inner-itinerary-div-no-data"
                  style={{ margin: "12px 0px" }}
                >
                  <h3 className="no-data-heading">
                    Enter cities to start building your itinerary!
                  </h3>
                </div>
              </div>
            </div>
          )}

        {itineraryPlanStore.visitDestinationList &&
          itineraryPlanStore.visitDestinationList.length > 1 && (
            <div className="stays-places-to-visit-section">
              <div className="section">
                <div className="stays-places-to-visit-heading">
                  <h1>Your stay and preferred places</h1>
                </div>
                <div className="tabs" key={"tabs"}>
                  <div className="btn-div">
                    <button
                      onClick={() => setActiveTab(0)}
                      className={
                        activeTab === 0 ? "stays-btn" : "stays-btn  tab-active"
                      }
                    >
                      Stays
                    </button>
                    <button
                      onClick={() => setActiveTab(1)}
                      className={
                        activeTab === 1
                          ? "places-to-visit-btn"
                          : "places-to-visit-btn tab-active"
                      }
                    >
                      Things to do
                    </button>
                  </div>
                </div>

                {/* {screenWidth > 700 && ( */}
                <div className="tab-content">
                  {activeTab === 0 &&
                    itineraryPlanStore.opportunityStays.map((stay) => {
                      return (
                        <ItineraryStaysView
                          cityName={stay.destination}
                          roomTypes={[]}
                          propertyId={stay.propertyId}
                          roomTypeId={stay.roomTypeId}
                          roomCount={stay.numRooms}
                          mealPlan={stay.mealPlan}
                          screenWidth={screenWidth}
                        />
                      );
                    })}
                  {activeTab === 1 && (
                    <div>
                      {itineraryPlanStore.placesToVisit.map(
                        (placeToVisit, index) => (
                          <ItineraryPlacesToVisitView
                            key={placeToVisit.id}
                            data={placeToVisit}
                            index={index + 1}
                            screenWidth={screenWidth}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
                {/* )} */}

                {/* {screenWidth < 700 && (
                <div className="tab-content-resp">
                  {activeTab === 0 &&
                    itineraryPlanStore.opportunityStays.map((stay) => {
                      return (
                        <ItineraryStaysView
                          cityName={stay.destination}
                          roomTypes={[]}
                          propertyId={stay.propertyId}
                          roomTypeId={stay.roomTypeId}
                          roomCount={stay.numRooms}
                          mealPlan={stay.mealPlan}
                          screenWidth={screenWidth}
                        />
                      );
                    })}
                  {activeTab === 1 && (
                    <div>
                      {itineraryPlanStore.placesToVisit.map(
                        (placeToVisit, index) => (
                          <ItineraryPlacesToVisitView
                            key={placeToVisit.id}
                            data={placeToVisit}
                            index={index + 1}
                            screenWidth={screenWidth}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              )} */}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default CustomItineraryView;
