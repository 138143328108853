import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { amenitiesIconMapping } from "src/constants/iconMapping";
import { experienceApiAxios } from "src/helpers/api_helper";
import { EVENTS, GET_PROPERTY_LISTING } from "src/helpers/url_helper";
import PropertyOverlay from "../propertyOverlay/PropertyOverlay";
import _ from "underscore";
import "./ItineraryStays.scss";
import RoomTypesContainer from "../details/RoomTypesContainer";
import "../details/Details.scss";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import { Property } from "src/models/models";
import ImageGalleryPopup from "../details/ImageGalleryPopup";
import moment from "moment";
import rightArrowImg from "../../assets/images/enable-right.png";
import leftArrowImg from "../../assets/images/enable-left.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CommonTooltip from "../common/CommonTooltip";
import {
  formatPropertyDescription,
  getValueAccomodationType,
} from "src/helpers/utils";
import { Rating, Tooltip } from "@mui/material";
import { getHeader } from "src/helpers/backend_helper";
import { Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
});
const ItineraryStays = observer(
  ({
    cityName,
    existingProperty,
    roomInfo,
    propertyConfigInfo,
    destinationIndex,
    beginDate,
    placeIndex,
  }: {
    cityName: string;
    existingProperty: any;
    roomInfo: any[];
    propertyConfigInfo: Property | undefined;
    destinationIndex: number;
    beginDate: Date | undefined;
    placeIndex: number;
  }) => {
    const [propertyDetails, setPropertyDetails] =
      useState<any>(existingProperty);
    const [propertyOverlay, setPropertyOverlay] = useState(false);
    const [selectedviewmoreid, setselectedviewmoreid] = useState("");
    const [facilityLength, setfacilityLength] = useState(4);
    const [singleItenaryDescription, setSingleItenaryDescription] =
      useState(350);
    const [singlExpandedRooms, setSingleExpandedRooms] = useState<{
      [key: string]: boolean;
    }>({});
    const [selectRoomTypes, setSelectRoomTypes] = useState<any[]>(roomInfo);
    const [propertyConfig, setPropertyConfig] = useState<Property>({
      id: -1,
      dayIndexFrom: propertyConfigInfo?.dayIndexFrom ?? "",
      dayIndexTo: propertyConfigInfo?.dayIndexTo ?? "",
      propertyId: propertyConfigInfo?.propertyId ?? "",
      propertyName: propertyConfigInfo?.propertyName ?? "",
      roomTypeId: propertyConfigInfo?.roomTypeId ?? "",
      roomTypeName: propertyConfigInfo?.roomTypeName ?? "",
      destination: propertyConfigInfo?.destination ?? "",
      cityId: propertyConfigInfo?.cityId ?? 0,
      numRooms: propertyConfigInfo?.numRooms ?? 0,
      destinationIndex: destinationIndex,
      fromDate: "",
      toDate: "",
      notes: "",
    });
    const [imageOverLayArray, setImageOverLayArray] = useState<any[]>([]);
    const [imageIndex, setImageIndex] = useState<any>(null);
    const fetchPropertyDetails = async () => {
      if (cityName.length > 3) {
        experienceApiAxios
          .get(
            `${GET_PROPERTY_LISTING}?pageSize=1&currentPage=${1}&search=${
              propertyDetails?.name ?? cityName
            }&channelOfSale=channelOfSale.Voyaah&isActive=true`
          )
          .then((response) => {
            if (
              response?.data?.propertyList &&
              response?.data?.propertyList?.length > 0
            ) {
              setPropertyDetails({
                ...response.data.propertyList[0],
                destinationIndex: destinationIndex,
              });
            }
          });
      }
    };

    const getIcon = (text: string) => {
      const textVar = text?.toLowerCase();
      let bestMatch = "";
      let bestMatchIndex = 0;
      let matchingIcons = amenitiesIconMapping.filter((icon: any) =>
        textVar?.includes(icon.keyword)
      );
      for (let i = 0; i < matchingIcons.length; i++) {
        if (matchingIcons[i].keyword !== undefined) {
          if (matchingIcons[i].keyword.length > bestMatch.length) {
            bestMatch = matchingIcons[i].keyword;
            bestMatchIndex = i;
          }
        }
      }
      if (matchingIcons.length === 0)
        return require(`../../assets/images/icons/highlights/default.png`);
      return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
    };

    const [showCarousel, setShowCarousel] = useState(false);

    const updateShowImageGalleryPopup = (data: any) => {
      setShowCarousel(data);
    };

    const toggleCarousel = () => {
      setShowCarousel(!showCarousel);
    };

    const [startIndex, setStartIndex] = useState(0);

    // Function to handle moving to the previous set of images in the carousel
    const prevImages = () => {
      if (startIndex > 0) {
        setStartIndex(startIndex - 3);
      }
    };

    // Function to handle moving to the next set of images in the carousel
    const nextImages = (carouselImagesLength: any) => {
      if (startIndex + 3 < carouselImagesLength) {
        setStartIndex(startIndex + 3);
      }
    };

    const selectedRoomTypes = (id: any, name: any, propertyId: any) => {
      const findRoomType = itineraryPlanStore.stayRoomTypes.find(
        (room) => room.destinationIndex === destinationIndex
      );
      if (findRoomType) {
        const deleteRoomType = selectRoomTypes.filter(
          (room) => room.destinationIndex !== destinationIndex
        );
        const deleteRoomTypeFromStore = itineraryPlanStore.stayRoomTypes.filter(
          (room) => room.destinationIndex !== destinationIndex
        );
        deleteRoomType.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          destinationIndex: destinationIndex,
          cityName,
        });
        deleteRoomTypeFromStore.push({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          destinationIndex: destinationIndex,
          cityName,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
        });
        setSelectRoomTypes(deleteRoomType);
        itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);

        const propertyListObj: Property = itineraryPlanStore.propertyList.find(
          (property: Property) => property.destinationIndex === destinationIndex
        )!;

        if (propertyListObj) {
          const filteredPropertyList = itineraryPlanStore.propertyList.filter(
            (property: Property) =>
              property.destinationIndex !== destinationIndex
          )!;

          filteredPropertyList.push({
            ...propertyListObj,
            mealPlan: "",
            // !if we use 1 as number of rooms then when we change the property visually value will display but while sending payload its going empty
            // numRooms: 1,
            numRooms: 0,
            roomTypeId: id,
            roomTypeName: name,
          });

          filteredPropertyList.sort(
            (a, b) => a.destinationIndex! - b.destinationIndex!
          );

          itineraryPlanStore.setPropertyList(filteredPropertyList);
        }
      } else {
        let tempRoom = selectRoomTypes;
        tempRoom = tempRoom.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          destinationIndex: destinationIndex,
          cityName: cityName,
        });
        let tempRoomStore = itineraryPlanStore.stayRoomTypes;
        tempRoomStore = tempRoomStore.concat({
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          destinationIndex: destinationIndex,
          cityName: cityName,
        });
        setPropertyConfig({
          ...propertyConfig,
          roomTypeId: id,
          roomTypeName: name,
          numRooms: 0,
        });
        setSelectRoomTypes(tempRoom);
        itineraryPlanStore.setStayRoomTypes(tempRoomStore);
      }
    };

    const getStayDates = () => {
      if (beginDate && placeIndex !== null && placeIndex !== undefined) {
        let numberOfDays: number = 0;
        if (placeIndex !== 0) {
          if (
            itineraryPlanStore.visitDestinationList.length === 2 &&
            itineraryPlanStore.visitDestinationList[0].cityName ===
              itineraryPlanStore.visitDestinationList[1].cityName
          ) {
            let startDate = new Date(beginDate);
            let endDate = new Date(beginDate);
            return ` - ${moment(startDate.setDate(startDate.getDate())).format(
              "DD-MM-YYYY"
            )} to ${moment(
              startDate.setDate(
                endDate.getDate() +
                  itineraryPlanStore.visitDestinationList[1].stayDuration
              )
            ).format("DD-MM-YYYY")}`;
          }

          numberOfDays = itineraryPlanStore.visitDestinationList
            .slice(0, placeIndex)
            .reduce((accumulator, currentObj) => {
              return accumulator + currentObj.stayDuration;
            }, 0);

          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` (${moment(
            startDate.setDate(startDate.getDate() + numberOfDays)
          ).format("DD-MM-YYYY")} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                numberOfDays +
                itineraryPlanStore.visitDestinationList[placeIndex].stayDuration
            )
          ).format("DD-MM-YYYY")})`;
        } else {
          let startDate = new Date(beginDate);
          let endDate = new Date(beginDate);
          return ` (${moment(startDate.setDate(startDate.getDate())).format(
            "DD-MM-YYYY"
          )} to ${moment(
            startDate.setDate(
              endDate.getDate() +
                itineraryPlanStore.visitDestinationList[placeIndex].stayDuration
            )
          ).format("DD-MM-YYYY")})`;
        }
      }

      return "";
    };

    const selectRoomNum = (room: any) => {
      if (room) {
        const tempPropertyConfig = {
          ...propertyConfig,
          destinationIndex: destinationIndex,
          numRooms: parseInt(room),
        };
        setPropertyConfig(tempPropertyConfig);
        const propertyexisting = itineraryPlanStore.propertyList.find(
          (prop: any) => prop.destinationIndex === destinationIndex
        );
        if (propertyexisting) {
          const tempProp = itineraryPlanStore.propertyList.filter(
            (prop: any) => prop.destinationIndex !== destinationIndex
          );
          tempProp.push(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProp);
        } else {
          let tempProperty: any[] = itineraryPlanStore.propertyList;
          tempProperty = tempProperty.concat(tempPropertyConfig);
          itineraryPlanStore.setPropertyList(tempProperty);
        }
      } else {
        return;
      }
    };

    useEffect(() => {
      const existingProp = itineraryPlanStore.selectedProperties.find(
        (property: any) =>
          property.city?.name.toLocaleLowerCase() ===
            cityName.toLocaleLowerCase() &&
          property.destinationIndex === destinationIndex
      );
      if (existingProp) {
        setPropertyDetails(existingProp);
        const roomType = itineraryPlanStore.stayRoomTypes.find(
          (room: any) => room.destinationIndex === destinationIndex
        );
        if (roomType) {
          setSelectRoomTypes([roomType]);
        }
      } else {
        fetchPropertyDetails();
      }
    }, [cityName]);

    useEffect(() => {
      if (propertyDetails) {
        const propertyConfigObj = {
          id: Math.floor(Math.random() * 100) * -1,
          dayIndexFrom: 0,
          dayIndexTo: 0,
          propertyId: propertyDetails.id,
          propertyName: propertyDetails.name,
          roomTypeId: propertyConfig.roomTypeId ?? "",
          roomTypeName: propertyConfig.roomTypeName ?? "",
          destination: cityName,
          cityId: propertyDetails.cityId,
          numRooms: propertyConfig?.numRooms ?? 0,
          destinationIndex: destinationIndex,
          fromDate: "",
          toDate: "",
          notes: "",
        };
        const existingProperty = itineraryPlanStore.selectedProperties.find(
          (property: any) =>
            property.city?.name.toLocaleLowerCase() ===
              cityName.toLocaleLowerCase() &&
            property.destinationIndex === destinationIndex
        );
        if (existingProperty) {
          const tempProperty = itineraryPlanStore.selectedProperties.filter(
            (property: any) => property.destinationIndex !== destinationIndex
          );
          tempProperty.push({
            ...propertyDetails,
            destinationIndex: destinationIndex,
          });

          const existingPropertyArr =
            itineraryPlanStore.existingProperties.filter(
              (property: any) => property.destinationIndex !== destinationIndex
            );
          existingPropertyArr.push({
            cityName: cityName,
            property: propertyDetails,
            destinationIndex: destinationIndex!,
          });

          const propertyListObj: Property =
            itineraryPlanStore.propertyList.find(
              (property: any) => property.destinationIndex === destinationIndex
            )!;

          const filteredPropertyList = itineraryPlanStore.propertyList.filter(
            (property: any) => property.destinationIndex !== destinationIndex
          );

          if (propertyListObj) {
            const propertyToPush = {
              ...propertyListObj,
              propertyId: propertyDetails.id,
              propertyName: propertyDetails.name,
            };
            filteredPropertyList.push(propertyToPush);

            itineraryPlanStore.setPropertyList(filteredPropertyList);
          }

          itineraryPlanStore.setSelectedProperties(tempProperty);
          itineraryPlanStore.setExistingProperties(existingPropertyArr);
        } else {
          let tempProperty = itineraryPlanStore.selectedProperties;

          const checkIfIndexPresent = tempProperty?.find(
            (property) => property.destinationIndex === destinationIndex
          );

          if (!checkIfIndexPresent) {
            tempProperty = tempProperty.concat({
              ...propertyDetails,
              destinationIndex: destinationIndex,
            });
            itineraryPlanStore.setSelectedProperties(tempProperty);
          } else {
            tempProperty = tempProperty.filter(
              (property) => property.destinationIndex !== destinationIndex
            );
            tempProperty = tempProperty.concat({
              ...propertyDetails,
              destinationIndex: destinationIndex,
            });
            itineraryPlanStore.setSelectedProperties(tempProperty);
          }

          let existingPropertyObj = itineraryPlanStore.existingProperties;

          const checkIfEPIndexPresent = existingPropertyObj?.find(
            (property) => property.destinationIndex === destinationIndex
          );

          if (!checkIfEPIndexPresent) {
            existingPropertyObj.push({
              cityName: cityName,
              property: propertyDetails,
              destinationIndex: destinationIndex!,
            });
            itineraryPlanStore.setExistingProperties(existingPropertyObj);
          } else {
            existingPropertyObj = existingPropertyObj?.filter(
              (property) => property.destinationIndex !== destinationIndex
            );

            existingPropertyObj.push({
              cityName: cityName,
              property: propertyDetails,
              destinationIndex: destinationIndex!,
            });
            itineraryPlanStore.setExistingProperties(existingPropertyObj);
          }
        }

        if (propertyDetails) {
          const imageArray: any[] = [];
          propertyDetails.mediaFolder.files.forEach((file: any) => {
            const tempImg = {
              imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`,
              imageCaption: file.fileName,
            };
            imageArray.push(tempImg);
          });

          const mediaOverLayArray = [
            {
              collectionType: "Additional",
              propertyId: null,
              roomTypeId: null,
              images: imageArray,
            },
          ];

          setImageOverLayArray(mediaOverLayArray);
        }

        setPropertyConfig(propertyConfigObj);
      }
    }, [propertyDetails]);

    const selectMealPlan = (mealPlan: string) => {
      const tempPropertyConfig = {
        ...propertyConfig,
        mealPlan: mealPlan,
        destinationIndex: destinationIndex,
      };
      setPropertyConfig(tempPropertyConfig);
      const propertyexisting = itineraryPlanStore.propertyList.find(
        (prop: any) => prop.destinationIndex === destinationIndex
      );
      if (propertyexisting) {
        const tempProp = itineraryPlanStore.propertyList.filter(
          (prop: any) => prop.destinationIndex !== destinationIndex
        );
        tempProp.push(tempPropertyConfig);
        itineraryPlanStore.setPropertyList(tempProp);
      } else {
        let tempProperty: any[] = itineraryPlanStore.propertyList;
        tempProperty = tempProperty.concat(tempPropertyConfig);
        itineraryPlanStore.setPropertyList(tempProperty);
      }
    };

    const moreFacilitylist = (total: number, propertyid: any) => {
      setfacilityLength(total);
      setselectedviewmoreid(propertyid);
    };

    // for single type stays
    const singleToggleAmenities = (roomId: string) => {
      setSingleExpandedRooms((prev) => ({
        ...prev,
        [roomId]: !prev[roomId],
      }));
    };

    const postEvent = async (eventBody: any) => {
      const headerResponse = await getHeader();
      const eventResponse = await experienceApiAxios.post(
        `${EVENTS}`,
        eventBody,
        {
          headers: headerResponse,
        }
      );

      if (eventResponse.status === 200) {
        return;
      }
    };

    const addRoomChangeEvent = async (
      propertyId: string,
      propertyName: string,
      roomId: string,
      roomName: string
    ) => {
      const localStorageData = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (localStorageData) {
        customerProfileId = JSON.parse(localStorageData!).id;
        const eventObj = {
          eventType: "ROOMCHANGE",
          payload: {
            packageId: null,
            packageName: null,
            propertyId: propertyId,
            propertyName: propertyName,
            roomId: roomId,
            roomName: roomName,
            customerProfileId: customerProfileId,
          },
        };

        await postEvent(eventObj);
      }
    };

    return (
      <div key={cityName}>
        {propertyDetails && (
          <div className="stays-section" id={`ci_stays_${placeIndex}`}>
            <ThemeProvider theme={theme}>
              <Grid container spacing={5}>
                {/* First Child */}
                <Grid item xs={12} md={5} xl={4}>
                  {propertyDetails?.mediaFolder?.files.length > 0 && (
                    <div className="property-stay-thumbnail">
                      <img
                        className="property-stay-img"
                        src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${propertyDetails.mediaFolder.files[0].fileName}`}
                      />
                      <button
                        className="carousel-toggle-button"
                        onClick={toggleCarousel}
                        id={`ci_btnViewMore_${placeIndex}`}
                      >
                        View more...
                      </button>
                    </div>
                  )}
                  <div className="carousel-container">
                    {/* Left arrow */}
                    <button
                      className="carousel-arrow left"
                      disabled={startIndex === 0}
                      id={`ci_btnPrevImage_${placeIndex}`}
                      onClick={prevImages}
                    >
                      <img
                        src={leftArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>

                    <div className="carousel-images">
                      {propertyDetails?.mediaFolder?.files.length > 0 &&
                        propertyDetails?.mediaFolder?.files
                          .slice(startIndex, startIndex + 3)
                          .map((file: any, index: any) => {
                            return (
                              <img
                                key={index}
                                src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/${file.fileName}`}
                                alt={file.fileName}
                                id={`pdp_imgCaraousel_${placeIndex}-${index}`}
                                onClick={(e) => {
                                  const imgIndex =
                                    propertyDetails?.mediaFolder?.files?.findIndex(
                                      (fileObj: { fileName: any }) =>
                                        fileObj.fileName === file.fileName
                                    );
                                  setImageIndex(imgIndex);
                                  setShowCarousel(!showCarousel);
                                }}
                              />
                            );
                          })}
                    </div>

                    <button
                      className="carousel-arrow right"
                      disabled={
                        startIndex + 3 >=
                        propertyDetails?.mediaFolder?.files.length
                      }
                      onClick={() => {
                        nextImages(propertyDetails.mediaFolder?.files.length);
                      }}
                      id={`ci_btnNextImage_${placeIndex}`}
                    >
                      <img
                        src={rightArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>
                  </div>
                </Grid>

                {/* Second Child */}
                <Grid item xs={12} md={7} xl={8}>
                  <div className="property-stay-content-button">
                    <div className="property-stay-div">
                      <div className="property-stay-header-div">
                        <div className="property-stay-header-txt test1">
                          <h1 className="title"> {propertyDetails.name}</h1>
                          <div>
                            <button
                              className="property-stay-header-btn"
                              id={`ci_btnSelect_${placeIndex}`}
                              onClick={() =>
                                setPropertyOverlay(!propertyOverlay)
                              }
                            >
                              Select
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="city-date-div">
                            {`${cityName} - ${
                              itineraryPlanStore.visitDestinationList[
                                placeIndex!
                              ].stayDuration
                            }N ${
                              itineraryPlanStore.visitDestinationList[
                                placeIndex!
                              ].stayDuration! + 1
                            }D`}{" "}
                            {getStayDates()}
                          </div>
                          {propertyDetails?.type !== null &&
                            propertyDetails?.type !== "" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                {(() => {
                                  const formattedDescription =
                                    formatPropertyDescription(
                                      propertyDetails?.type
                                    );

                                  if (!formattedDescription) return null;

                                  const descriptionText =
                                    formattedDescription.length > 1
                                      ? formattedDescription.replace(
                                          /\s\d+$/,
                                          ""
                                        )
                                      : null;

                                  const ratingValue =
                                    formattedDescription.length === 1
                                      ? Number(formattedDescription)
                                      : Number(
                                          formattedDescription.match(/\d+/)?.[0]
                                        ) || 0;

                                  return (
                                    <>
                                      {descriptionText && (
                                        <span style={{ fontSize: "14px" }}>
                                          {descriptionText}
                                        </span>
                                      )}
                                      <Rating value={ratingValue} readOnly />
                                    </>
                                  );
                                })()}
                              </div>
                            )}
                        </div>
                        <div className="property-stay-details-location-date">
                          <div className="property-stay-details-bubble">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 4,
                              }}
                            >
                              <LocationOnIcon
                                sx={{ height: "16px", color: "#7f7f7f" }}
                              />
                              <p
                                className="stay-location-date-label"
                                style={{ marginTop: 0, flexShrink: 0 }}
                              >
                                location:
                              </p>
                            </div>
                            <span className="stay-location-date-div">
                              {propertyDetails?.address}
                            </span>
                          </div>

                          {propertyDetails?.accommodationType &&
                            propertyDetails?.accommodationType !== null &&
                            propertyDetails?.accommodationType !== "" && (
                              <div
                                className="property-stay-details-bubble"
                                style={{ padding: "3px 12px" }}
                              >
                                <span className="pckg-type">
                                  Type:{" "}
                                  {getValueAccomodationType(
                                    propertyDetails?.accommodationType
                                  )}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{ textAlign: "justify" }}
                          className="property-stay-description test1"
                          dangerouslySetInnerHTML={{
                            __html:
                              propertyDetails?.descriptionText?.length > 350
                                ? propertyDetails?.descriptionText?.substring(
                                    0,
                                    singleItenaryDescription
                                  )
                                : propertyDetails?.descriptionText,
                          }}
                        />
                        {propertyDetails?.descriptionText?.length > 350 && (
                          <button
                            style={{
                              alignSelf: "flex-start",
                              fontSize: "14px",
                              color: "#2e8b98",
                              cursor: "pointer",
                              border: "none",
                              backgroundColor: "transparent",
                              padding: "3px 0px",
                            }}
                            id={`ci_btnDescription_${placeIndex}`}
                            onClick={() => {
                              if (singleItenaryDescription === 350) {
                                setSingleItenaryDescription(
                                  propertyDetails.descriptionText.length
                                );
                              } else {
                                setSingleItenaryDescription(350);
                              }
                            }}
                          >
                            {singleItenaryDescription === 350
                              ? "View more"
                              : "View less"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </ThemeProvider>

            <div className="property-facilities">
              <ul className="inline-block">
                {_.compact(propertyDetails.facilities).length > 0 &&
                  _.compact(propertyDetails.facilities)
                    ?.slice(0, facilityLength)
                    .map((item: any, index: number) => {
                      return (
                        <li
                          style={{ fontSize: "14px" }}
                          key={`package_hotel_facilities_${index}`}
                        >
                          <span style={{ width: "20px" }}>
                            <img
                              src={getIcon(item?.name)}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          {item?.name}&nbsp;
                          <CommonTooltip
                            title={`${
                              item?.extractedJson?.toBePaid
                                ? "This facility can have an additional charge to be paid at the property"
                                : ""
                            }`}
                            arrow
                          >
                            <span>
                              {item?.extractedJson?.toBePaid && "($)"}
                            </span>
                          </CommonTooltip>
                        </li>
                      );
                    })}
                {_.compact(propertyDetails.facilities).length === 0 && (
                  <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                )}
                {propertyDetails?.mediaFolder?.files.length ===
                  facilityLength &&
                propertyDetails?.id === selectedviewmoreid &&
                propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    id={`ci_btnFacilitiesLess_${placeIndex}`}
                    onClick={() => {
                      moreFacilitylist(4, propertyDetails.id);
                    }}
                  >
                    view less
                  </li>
                ) : propertyDetails?.facilities.length > 4 ? (
                  <li
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#2e8b98",
                    }}
                    id={`ci_btnFacilitiesMore_${placeIndex}`}
                    onClick={() => {
                      moreFacilitylist(
                        propertyDetails.mediaFolder?.files.length,
                        propertyDetails.id
                      );
                    }}
                  >
                    view more
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div className="room-child-wrapper">
              {propertyDetails.roomTypes &&
                propertyDetails.roomTypes.map((room: any, roomIndex: any) => {
                  if (room?.statusCode === "roomTypeStatus.Active") {
                    let roomNum = 0;
                    let roomMealType = "";
                    const roomFlag = selectRoomTypes.some(
                      (rooms) =>
                        rooms.roomId === room.id &&
                        rooms.destinationIndex === destinationIndex
                    );

                    if (roomFlag) {
                      const propertyFind = itineraryPlanStore.propertyList.find(
                        (property) =>
                          property.destinationIndex === destinationIndex
                      );

                      roomNum = propertyFind?.numRooms ?? 0;
                      roomMealType = propertyFind?.mealPlan ?? "";
                    }
                    const imgArr: any[] = [];
                    room.mediaFolder.files.forEach((file: any) => {
                      const tempImg = {
                        imageCaption: file.fileName,
                        imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${propertyDetails.mediaFolder.url}/room/${room.mediaFolder.url}/${file.fileName}`,
                      };
                      imgArr.push(tempImg);
                    });
                    return (
                      <div>
                        <div className="room-info test1">
                          <div className="radio-img-carosel">
                            <div className="roomselection-radiobtn">
                              <input
                                checked={roomFlag}
                                onChange={async () => {
                                  selectedRoomTypes(
                                    room.id,
                                    room.name,
                                    propertyDetails.id
                                  );

                                  await addRoomChangeEvent(
                                    propertyDetails.id,
                                    propertyDetails.name,
                                    room.id,
                                    room.name
                                  );
                                }}
                                type="radio"
                                id={`ci_chkRoomSelect_${placeIndex}_${roomIndex}`}
                              />
                            </div>
                            <RoomTypesContainer imageArr={imgArr} />
                          </div>
                          <div className="room-details-div">
                            <div className="room-header">{room.name}</div>
                            <div className="room-details-text">
                              {room?.beds[0]?.count === undefined
                                ? ""
                                : room?.beds[0]?.count + "-"}
                              {room?.beds[0]?.description === undefined
                                ? ""
                                : room?.beds[0]?.description !== undefined
                                ? room?.beds[0]?.description?.split("bed")[0] +
                                  "bed"
                                : ""}
                            </div>
                            <div className="facilities-room-div">
                              {room.amenities
                                .slice(
                                  0,
                                  singlExpandedRooms[room.id] || false
                                    ? room.amenities.length
                                    : 4
                                )
                                .map(
                                  (
                                    amenity: {
                                      name: string;
                                      amenityType: string;
                                      extractedJson: any;
                                    },
                                    index: number
                                  ) => {
                                    return (
                                      <>
                                        <span
                                          key={`package_room_amenities_${index}`}
                                          style={{
                                            width: "14px",
                                          }}
                                        >
                                          <img
                                            src={getIcon(amenity.amenityType)}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </span>
                                        <span>{amenity.name}&nbsp;</span>
                                        <CommonTooltip
                                          title={`${
                                            amenity?.extractedJson?.toBePaid
                                              ? "This amenity can have an additional charge to be paid at the property"
                                              : ""
                                          }`}
                                          arrow
                                        >
                                          <span>
                                            {amenity?.extractedJson?.toBePaid &&
                                              "($)"}
                                          </span>
                                        </CommonTooltip>
                                      </>
                                    );
                                  }
                                )}
                              {room.amenities.length > 4 && (
                                <button
                                  style={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    color: "#2e8b98",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    padding: "0",
                                  }}
                                  id={`ci_btnAmenities_${placeIndex}_${roomIndex}`}
                                  onClick={() => singleToggleAmenities(room.id)}
                                >
                                  {singlExpandedRooms[room.id] || false
                                    ? "View less"
                                    : "View more"}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="meal-wrapper">
                            <div className="room-count-div">
                              <div className="dropdown-label">Meals:</div>
                              <select
                                disabled={!roomFlag}
                                className="room-type-dropdown"
                                id={`ci_selMeals_${placeIndex}_${roomIndex}`}
                                value={roomMealType}
                                onChange={(e) => selectMealPlan(e.target.value)}
                              >
                                <option value={""}>Choose a meal plan</option>
                                {propertyDetails?.mealPlanList &&
                                  propertyDetails?.mealPlanList?.length > 0 &&
                                  propertyDetails?.mealPlanList
                                    .find(
                                      (mealPlan: { roomTypeId: any }) =>
                                        mealPlan.roomTypeId === room.id
                                    )
                                    .mealPlan?.map((mealPlanName: any) => {
                                      return (
                                        <option
                                          value={mealPlanName.code}
                                          id={`ci_optMeal_${placeIndex}_${roomIndex}_${mealPlanName?.code}`}
                                        >
                                          {mealPlanName.description}
                                        </option>
                                      );
                                    })}
                              </select>
                            </div>
                            <div className="room-count-div">
                              <div className="dropdown-label">Rooms:</div>
                              <select
                                className="room-type-dropdown"
                                value={roomNum}
                                disabled={!roomFlag}
                                onChange={(e) => {
                                  selectRoomNum(e.target.value);
                                }}
                                id={`ci_selRooms_${placeIndex}_${roomIndex}`}
                              >
                                <option value={0}>
                                  Select number of rooms
                                </option>
                                <option value={1}>{"1 Room"}</option>
                                <option value={2}>{"2 Rooms"}</option>
                                <option value={3}>{"3 Rooms"}</option>
                                <option value={4}>{"4 Rooms"}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
        {propertyOverlay && (
          <PropertyOverlay
            onClosePropertyOverlay={setPropertyOverlay}
            cityName={cityName}
            newPropertyDetails={setPropertyDetails}
            propertyId={propertyDetails.id}
            removeRoomtype={undefined}
            opportunityStaysId={false}
            loader={null}
            destinationIndex={destinationIndex}
          />
        )}
        {showCarousel && (
          <ImageGalleryPopup
            packageData={imageOverLayArray}
            showImageGalleryPopup={showCarousel}
            updateShowImageGalleryPopup={updateShowImageGalleryPopup}
            imageIndex={imageIndex}
          />
        )}
        {!propertyDetails && (
          <div className="stays-section">
            <div className="city-date-div" style={{ textAlign: "center" }}>
              {`There are no stays in ${cityName} for ${
                itineraryPlanStore.visitDestinationList[placeIndex!]
                  .stayDuration
              }N ${
                itineraryPlanStore.visitDestinationList[placeIndex!]
                  .stayDuration! + 1
              }D`}{" "}
              {getStayDates()}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ItineraryStays;
